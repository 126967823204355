import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { User_jsonld_user_read_file_read_dealer_read_client_read_storeman_read_company_details_read_address_read as User } from '@expanzi/api-types';

const usePrefillDealerDetails = (dealer: User['dealer']) => {
    const { setValue } = useFormContext();
    const [isPrefill, setIsPrefill] = useState(false);

    useEffect(() => {
        if (dealer) {
            setValue('deliveryDetails.contactPersonEmail', isPrefill ? dealer.billingDetails?.contactPersonEmail : '');
            setValue(
                'deliveryDetails.contactPersonPhoneNumber',
                isPrefill ? dealer.billingDetails?.contactPersonPhoneNumber : '',
            );
            setValue(
                'deliveryDetails.contactPersonFirstName',
                isPrefill ? dealer.billingDetails?.contactPersonFirstName : '',
            );
            setValue(
                'deliveryDetails.contactPersonLastName',
                isPrefill ? dealer.billingDetails?.contactPersonLastName : '',
            );
            setValue('deliveryDetails.address.country', isPrefill ? dealer?.billingDetails?.address?.country : '');
            setValue('deliveryDetails.address.street', isPrefill ? dealer?.billingDetails?.address?.street : '');
            setValue(
                'deliveryDetails.address.houseNumber',
                isPrefill ? dealer?.billingDetails?.address?.houseNumber : '',
            );
            setValue('deliveryDetails.address.note', isPrefill ? dealer?.billingDetails?.address?.note : null);
            setValue('deliveryDetails.address.postcode', isPrefill ? dealer?.billingDetails?.address?.postcode : '');
            setValue('deliveryDetails.address.city', isPrefill ? dealer?.billingDetails?.address?.city : '');
            setValue(
                'deliveryDetails.name',
                isPrefill
                    ? `${dealer?.billingDetails?.contactPersonFirstName} ${dealer?.billingDetails?.contactPersonLastName}`
                    : '',
            );
            setValue('deliveryDetails.ico', isPrefill ? dealer?.billingDetails?.ico : null);
            setValue('deliveryDetails.dic', isPrefill ? dealer?.billingDetails?.dic : null);
        }
    }, [isPrefill, dealer]);

    return {
        isPrefill,
        setIsPrefill,
    };
};

export default usePrefillDealerDetails;
