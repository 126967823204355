import React from 'react';
import { useTranslation } from '@/lib/i18n';
import { useFormContext } from 'react-hook-form';
import { Checkbox, Input, Text } from '@/components/ui';
import type { DeliveryInfoFormValues } from '@/lib/resources/cart';
import { getFormError } from '@/lib/form';

type InvoiceFormProps = DeliveryInfoFormValues & {
    isDisabled?: boolean;
};

const InvoicePartForm: React.FC<InvoiceFormProps> = (props) => {
    const { isDisabled } = props;

    const { t } = useTranslation();
    const { register, watch, formState } = useFormContext();

    const getError = getFormError(t, formState.errors);

    return (
        <>
            <div className="col-span-2 border-b-2" />
            <div className="col-span-2 my-5">
                <Checkbox defaultChecked={false} {...register('invoiceWanted')}>
                    <Text.Body bold as="span">
                        {t('delivery_information_form.company_purchase')}
                    </Text.Body>
                </Checkbox>
            </div>
            {watch('invoiceWanted') && (
                <div className="flex flex-col gap-6 lg:grid lg:grid-cols-2">
                    <Input
                        required
                        id="company_name"
                        disabled={isDisabled}
                        classNameWrapper="col-span-2"
                        label={t('delivery_information_form.company_name')}
                        placeholder={t('delivery_information_form.company_name')}
                        error={getError('billingDetails.name')}
                        {...register('billingDetails.name')}
                    />
                    <Input
                        required
                        id="ico"
                        disabled={isDisabled}
                        placeholder={t('placeholder.cin')}
                        label={t('delivery_information_form.cin')}
                        error={getError('billingDetails.ico')}
                        {...register('billingDetails.ico')}
                    />
                    <Input
                        id="vatin"
                        disabled={isDisabled}
                        placeholder={t('placeholder.vatin')}
                        label={`${t('delivery_information_form.vatin')} (${t('not_mandatory')})`}
                        error={getError('billingDetails.dic')}
                        {...register('billingDetails.dic')}
                    />
                </div>
            )}
        </>
    );
};

export default InvoicePartForm;
