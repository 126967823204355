import { cva } from 'class-variance-authority';

import type { VariantProps } from 'class-variance-authority';

export const variants = cva(
    ['flex', 'gap-3', 'p-4', 'border', 'text-primary-600', 'rounded-xl', 'z-50', 'animate-fade-in'],
    {
        variants: {
            type: {
                error: ['bg-danger-100', 'border-danger-medium'],
                success: ['bg-success-light', 'border-success-medium'],
                info: ['bg-primary-100', 'border-primary-300'],
            },
        },
        defaultVariants: {
            type: 'error',
        },
    },
);

export type ComponentVariantProps = VariantProps<typeof variants>;
