import React from 'react';
import { Checkmark } from '@/svg';
import { request } from '@/lib/api';
import { useTranslation } from '@/lib/i18n';

import { Text, Section, Input, Textarea, Button, Recaptcha } from '..';
import PhoneInput from '@/components/ui/form/PhoneInput';
import { FormProvider, useForm } from 'react-hook-form';
import { getFormError } from '@/lib/form';
import { zodResolver } from '@hookform/resolvers/zod';
import { schema } from '@/components/ui/contact-form/validations';

const ContactForm: React.FC = () => {
    const { t } = useTranslation();
    const containerRef = React.useRef<HTMLDivElement>(null);
    const [success, setSuccess] = React.useState(false);

    const formMethods = useForm<{
        firstName: string;
        lastName: string;
        email: string;
        phoneNumber: string;
        message: string;
        captcha: string;
    }>({
        defaultValues: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            message: '',
            captcha: import.meta.env.PUBLIC_GOOGLE_RECAPTCHA_KEY,
        },
        resolver: zodResolver(schema),
        mode: 'onBlur',
    });

    const getError = getFormError(t, formMethods.formState.errors);

    const onSubmit = (data: any) => {
        request('/api/public/questions', {
            method: 'POST',
            body: data,
        }).then(() => {
            setSuccess(true);
        });
    };

    React.useEffect(() => {
        let timeoutId: number | undefined = undefined;
        if (success) {
            timeoutId = window.setTimeout(() => setSuccess(false), 3000);
            formMethods.reset();
        }
        return () => clearTimeout(timeoutId);
    }, [success]);

    return (
        <Section className="bg-white">
            <Text.H1 className="uppercase">{t('contact_form.title')}</Text.H1>
            <div className="flex gap-x-20">
                <div className="h-[590px] w-[420px] shrink-0 rounded-full max-xl:hidden">
                    <img
                        className="w-full object-cover"
                        src="/img/contact-form-cover.png"
                        height="885"
                        width="629"
                        aria-hidden="true"
                        loading="lazy"
                        alt=""
                    />
                </div>
                <div className="grow" ref={containerRef}>
                    {success ? (
                        <div
                            className="flex grow flex-col items-center justify-center gap-y-6 rounded-md bg-gray-50 px-4 py-20 text-center"
                            style={{ height: containerRef.current?.clientHeight }}
                        >
                            <Checkmark className="text-primary-500" />
                            <Text.H3 className="text-primary-600">Vaše zpráva byla odeslána</Text.H3>
                        </div>
                    ) : (
                        <FormProvider {...formMethods}>
                            <form className="flex grow flex-col gap-6" onSubmit={formMethods.handleSubmit(onSubmit)}>
                                <Text.H2 className="max-xl:hidden">{t('contact_form.write_to_us')}</Text.H2>
                                <div className="grid gap-6 lg:grid-cols-2">
                                    <Input
                                        required
                                        id="firstName"
                                        label={t('contact_form.first_name')}
                                        placeholder={t('contact_form.first_name')}
                                        error={getError('firstName')}
                                        {...formMethods.register('firstName')}
                                    />
                                    <Input
                                        required
                                        id="lastName"
                                        label={t('contact_form.last_name')}
                                        placeholder={t('contact_form.last_name')}
                                        error={getError('lastName')}
                                        {...formMethods.register('lastName')}
                                    />
                                    <Input
                                        required
                                        id="contact_email"
                                        type="email"
                                        label={t('contact_form.email')}
                                        placeholder={t('placeholder.email')}
                                        error={getError('email')}
                                        {...formMethods.register('email')}
                                    />
                                    <PhoneInput
                                        required
                                        id="phone"
                                        label={t('contact_form.phone')}
                                        placeholder={t('placeholder.phone')}
                                        error={getError('phoneNumber')}
                                        {...formMethods.register('phoneNumber')}
                                    />
                                    <Textarea
                                        required
                                        id="message"
                                        label={t('contact_form.message')}
                                        error={getError('message')}
                                        {...formMethods.register('message')}
                                        classNameWrapper="lg:col-span-2"
                                    />
                                </div>
                                <Recaptcha recaptchaKey={import.meta.env.PUBLIC_GOOGLE_RECAPTCHA_KEY} />
                                <div className="flex items-center gap-6 max-md:flex-col-reverse">
                                    <div className="text-gray space-y-5">
                                        <Text.Body
                                            className="leading-none"
                                            dangerouslySetInnerHTML={{
                                                __html: t('contact_form.personal_data_processing', {
                                                    href: '/EXPANZI_Zasady_ochrany_osobnich_udaju.pdf',
                                                }),
                                            }}
                                        />
                                        <Text.Body
                                            className="leading-none"
                                            dangerouslySetInnerHTML={{
                                                __html: t('contact_form.protected_by_recaptcha', {
                                                    href: 'https://policies.google.com/privacy',
                                                }),
                                            }}
                                        />
                                    </div>
                                    <Button className="min-w-[16.75rem] max-md:w-full md:ml-auto" type="submit">
                                        {t('submit')}
                                    </Button>
                                </div>
                            </form>
                        </FormProvider>
                    )}
                </div>
            </div>
        </Section>
    );
};

export default ContactForm;
