'use client';

import React, { useEffect, useState } from 'react';

import { CircleAlert, CircleCheck, Info, X } from 'lucide-react';

import Text from '../text/Text';
import { cn } from '../utils/utils';

import { variants } from './variants';
import type { ComponentVariantProps } from './variants';

type AlertAttributes = React.HTMLAttributes<HTMLElement>;

export type AlertProps = ComponentVariantProps &
    AlertAttributes & {
        title?: string;
        link?: { href: string; text: string };
        disappearable?: boolean;
        closeable?: boolean;
        duration?: number;
    };

const Alert: React.FC<AlertProps> = ({
    title,
    link,
    className,
    disappearable,
    closeable,
    duration = 1500,
    children,
    type,
    ...rest
}) => {
    const [isVisible, setIsVisible] = useState(true);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (disappearable) {
            const timer = setTimeout(() => setFadeOut(true), duration);
            const visibilityTimer = setTimeout(() => setIsVisible(false), duration + 500);

            return () => {
                clearTimeout(timer);
                clearTimeout(visibilityTimer);
            };
        }
    }, [disappearable, duration]);

    const handleClose = () => {
        setIsVisible(false);
    };

    if (!isVisible) {
        return null;
    }

    return (
        <div className={cn(variants({ type }), { 'animate-fade-out': fadeOut }, className)} {...rest}>
            {type === 'error' ? (
                <CircleAlert className="shrink-0" />
            ) : type === 'success' ? (
                <CircleCheck className="shrink-0" />
            ) : (
                <Info className="shrink-0" />
            )}
            <div className="flex flex-col gap-2">
                {title && <Text.Body className="font-semibold uppercase">{title}</Text.Body>}
                <Text.Body as="div">{children}</Text.Body>
                {link && (
                    <Text.Body className="underline">
                        <a href={link.href}>{link.text}</a>
                    </Text.Body>
                )}
            </div>
            {closeable && (
                <button onClick={handleClose}>
                    <X />
                </button>
            )}
        </div>
    );
};

export default Alert;
