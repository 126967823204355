import React, { useEffect, useState } from 'react';

import { PhoneInput } from '@/components/ui';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import type {
    CompanyDetails_jsonld_client_read_companyDetails_read_address_read as Company,
    User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User,
} from '@expanzi/api-types';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import { getFormError } from '@/lib/form';
import { useMutation } from '@tanstack/react-query';
import { request } from '@/lib/api';
import { queryClient } from '@/lib/store';
import { billingDetailsSchema } from '@/components/ui/profile/validations.ts';
import { getHydraId } from '@/lib/hydra.ts';
import { ENTITY_TYPES } from '@/components/ui/profile/types.ts';
import { Alert, Button, Checkbox, Select, Text, TextInput } from '@expanzi/ui-library';
import { isDealer } from '@/lib/role.ts';

interface Props {
    countries: CountryCodelist[];
    user: User | null;
    token?: string | null;
    setUnsavedChanges?: (val: boolean) => void;
}

const BillingDetailsForm: React.FC<Props> = (props) => {
    const { countries = [], user, token, setUnsavedChanges } = props;

    const { t } = useTranslation();

    const userIsDealer = isDealer(user);

    const getDetails = () => {
        if (userIsDealer) {
            return {
                entityType: user?.dealer?.billingDetails?.entityType || ENTITY_TYPES.PERSON,
                ...user?.dealer?.billingDetails,
            };
        }

        return {
            entityType: user?.client?.billingDetails?.entityType || ENTITY_TYPES.PERSON,
            ...user?.client?.billingDetails,
        };
    };

    const formMethods = useForm<Company>({
        resolver: zodResolver(billingDetailsSchema),
        defaultValues: getDetails(),
        mode: 'onBlur',
    });
    const [isEditing, setIsEditing] = useState(false);

    const cancelEdit = () => {
        setIsEditing(false);
        formMethods.reset(getDetails());
    };

    setUnsavedChanges?.(formMethods.formState.isDirty);

    const countryOptions = countries.map((country) => ({ label: country.name ?? '', value: country.id ?? '' }));

    useEffect(() => {}, [formMethods.formState.errors]);
    const getError = getFormError(t, formMethods.formState.errors);

    const { mutate, isPending, isSuccess, isError } = useMutation<unknown, unknown, Company>(
        {
            mutationKey: ['billing', user?.id],
            mutationFn: (body) =>
                request(`/api/users/${user?.id}`, {
                    body: {
                        profile: {
                            billingDetails: body,
                        },
                    },
                    token,
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/merge-patch+json',
                    },
                }),
            onSuccess: () => {
                setIsEditing(false);
                formMethods.reset(formMethods.getValues());
            },
        },
        queryClient,
    );

    const entityType = getHydraId(formMethods.watch('entityType'));
    const dic = formMethods.watch('dic');

    const changeEntityType = () => {
        if (entityType === 'company') {
            formMethods.setValue('entityType', ENTITY_TYPES.PERSON);
            return;
        }

        formMethods.setValue('entityType', ENTITY_TYPES.COMPANY);
    };

    return (
        <FormProvider {...formMethods}>
            <form className="mt-6 rounded-xl bg-white p-8" onSubmit={formMethods.handleSubmit((data) => mutate(data))}>
                {!userIsDealer && (
                    <div className="col-span-2">
                        <Checkbox
                            checked={entityType === 'company'}
                            onChange={() => changeEntityType()}
                            disabled={!isEditing}
                            value="/api/public/companyEntityType/company"
                        >
                            <Text.Body bold as="span">
                                {t('delivery_information_form.company_purchase')}
                            </Text.Body>
                        </Checkbox>
                    </div>
                )}
                <div className="flex flex-col items-baseline gap-x-6 lg:grid lg:grid-cols-6">
                    {entityType === 'company' && (
                        <>
                            <TextInput
                                required
                                type="text"
                                id="company_name"
                                disabled={userIsDealer || !isEditing}
                                error={getError('name')}
                                label={t('delivery_information_form.company_name')}
                                placeholder={t('delivery_information_form.company_name')}
                                classNameWrapper="col-span-3"
                                {...formMethods.register('name')}
                            />
                            {userIsDealer ? (
                                <TextInput
                                    required
                                    type="text"
                                    id="contactPersonRelation"
                                    error={getError('contactPersonRelation')}
                                    disabled={userIsDealer || !isEditing}
                                    label={t('delivery_information_form.contactPersonRelation')}
                                    placeholder={t('delivery_information_form.company_name')}
                                    classNameWrapper="col-span-3"
                                    {...formMethods.register('contactPersonRelation')}
                                />
                            ) : (
                                <div className="col-span-3" />
                            )}
                        </>
                    )}
                    {entityType !== 'person' && (
                        <>
                            <TextInput
                                required
                                id="ico"
                                type="text"
                                disabled={userIsDealer || !isEditing}
                                error={getError('ico')}
                                placeholder={t('placeholder.cin')}
                                label={t('delivery_information_form.cin')}
                                classNameWrapper="col-span-3"
                                {...formMethods.register('ico')}
                            />
                            {dic || !userIsDealer ? (
                                <TextInput
                                    id="vatin"
                                    type="text"
                                    disabled={userIsDealer || !isEditing}
                                    placeholder={t('placeholder.vatin')}
                                    label={`${t('delivery_information_form.vatin')} (${t('not_mandatory')})`}
                                    classNameWrapper="col-span-3"
                                    {...formMethods.register('dic')}
                                />
                            ) : (
                                <div className="col-span-3" />
                            )}
                        </>
                    )}
                    <TextInput
                        required
                        id="first_name"
                        disabled={userIsDealer || !isEditing}
                        label={t('contact_form.first_name')}
                        placeholder={t('contact_form.first_name')}
                        error={getError('contactPersonFirstName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonFirstName')}
                    />
                    <TextInput
                        required
                        id="last_name"
                        disabled={userIsDealer || !isEditing}
                        label={t('contact_form.last_name')}
                        placeholder={t('contact_form.last_name')}
                        error={getError('contactPersonLastName')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonLastName')}
                    />
                    <TextInput
                        required
                        id="email"
                        type="text"
                        disabled={userIsDealer || !isEditing}
                        label={t('contact_form.email')}
                        placeholder={t('contact_form.email')}
                        error={getError('contactPersonEmail')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonEmail')}
                    />
                    <PhoneInput
                        required
                        id="phone"
                        disabled={userIsDealer || !isEditing}
                        label={t('contact_form.phone')}
                        placeholder={t('placeholder.phone')}
                        error={getError('contactPersonPhoneNumber')}
                        classNameWrapper="col-span-3"
                        {...formMethods.register('contactPersonPhoneNumber')}
                    />
                    <Select
                        required
                        id="country"
                        disabled={userIsDealer || !isEditing}
                        options={countryOptions}
                        label={t('address.country')}
                        defaultValue="/api/public/countries/CZ"
                        error={getError('billingDetails.contactPersonPhoneNumber')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.country')}
                    />
                    <TextInput
                        required
                        id="street"
                        type="text"
                        disabled={userIsDealer || !isEditing}
                        label={t('address.street')}
                        placeholder={t('placeholder.street')}
                        error={getError('address.street')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.street')}
                    />
                    <TextInput
                        required
                        type="text"
                        id="houseNumber"
                        disabled={userIsDealer || !isEditing}
                        label={t('address.street_number')}
                        placeholder={t('placeholder.street_number')}
                        error={getError('address.houseNumber')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.houseNumber')}
                    />
                    <TextInput
                        required
                        id="zip"
                        type="text"
                        disabled={userIsDealer || !isEditing}
                        label={t('address.zip')}
                        placeholder={t('placeholder.zip')}
                        error={getError('address.postcode')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.postcode')}
                    />
                    <TextInput
                        required
                        id="city"
                        type="text"
                        disabled={userIsDealer || !isEditing}
                        label={t('address.city')}
                        placeholder={t('placeholder.city')}
                        error={getError('address.city')}
                        classNameWrapper="col-span-2"
                        {...formMethods.register('address.city')}
                    />
                </div>
                {userIsDealer ? (
                    <Text.Body as="div" className="mt-6">
                        {t('forChange')}
                        <a href={`mailto:office@expanzi.eu`} className="text-primary-500 hover:underline">
                            office@expanzi.eu.
                        </a>
                    </Text.Body>
                ) : (
                    <div className="mt-6 flex flex-1 flex-col items-center gap-4 lg:flex-row">
                        {(isError || isSuccess) && (
                            <Alert type={isSuccess ? 'success' : 'error'} disappearable>
                                {isSuccess
                                    ? t('profile.delivery_details_form.success')
                                    : t('profile.delivery_details_form.error')}
                            </Alert>
                        )}

                        {isEditing ? (
                            <div className="flex flex-1 items-end justify-between">
                                <Text as="p">{t('fields_required')}</Text>
                                <div className="flex flex-row gap-4">
                                    <Button
                                        variant="outline"
                                        color="dark"
                                        size="md"
                                        rounded
                                        disabled={isPending}
                                        type="button"
                                        onClick={cancelEdit}
                                    >
                                        {t('cancel')}
                                    </Button>
                                    <Button
                                        variant="default"
                                        color="dark"
                                        size="md"
                                        rounded
                                        loading={isPending}
                                        type="submit"
                                    >
                                        {t('save_changes')}
                                    </Button>
                                </div>
                            </div>
                        ) : (
                            <Button
                                variant="default"
                                color="dark"
                                size="md"
                                rounded
                                type="button"
                                onClick={() => setIsEditing(true)}
                                className="ml-auto"
                            >
                                {t('edit')}
                            </Button>
                        )}
                    </div>
                )}
            </form>
        </FormProvider>
    );
};

export default BillingDetailsForm;
