import React, { useState } from 'react';
import { signOut } from 'auth-astro/client';
import { useTranslation } from '@/lib/i18n';
import type { CountryCodelist } from '@/lib/resources/codelist';
import type {
    Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read as Invoice,
    User_jsonld_user_read_file_read_dealer_read_overview_client_read_overview_storeman_read_company_details_read as User,
} from '@expanzi/api-types';

import Text from '../text/Text';
import Pagination from '../pagination/Pagination';
import OrderLink from '../order-link/OrderLink';
import BillingDetailsForm from './BillingDetailsForm';
import DeliveryDetailsForm from './DeliveryDetailsForm';
import { Dialog, DialogContent, NoOrdersCard } from '@/components/ui';
import ProfileSettings from './ProfileSettings';
import type { DeliveryMethod } from '@/lib/resources/delivery.ts';
import { getUserName } from '@/lib/utils.ts';
import { resetCartDetails } from '@/lib/cart.ts';
import { Button, Tabs, TabsContent, TabsList, TabsTrigger } from '@expanzi/ui-library';

export type ProfileProps = {
    invoices: {
        data: Invoice[];
        url: string;
        page: number;
        itemsPerPage: number;
        totalItems: number;
    };
    token?: string | null;
    countries: CountryCodelist[];
    user: User;
    deliveryMethods: DeliveryMethod[];
};

const Profile: React.FC<ProfileProps> = (props) => {
    const { user, invoices, countries, token, deliveryMethods } = props;
    const { t } = useTranslation();

    const filteredInvoices = invoices.data.filter((invoice) => !invoice.licenseOrder);

    const [tab, setTab] = useState('orders');
    const [unsavedChanges, setUnsavedChanges] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [nextTab, setNextTab] = useState<string | null>(null);

    const handleTabChange = (tab: string) => {
        if (unsavedChanges) {
            setNextTab(tab);
            setShowDialog(true);
        } else {
            setTab(tab);
        }
    };

    const handleConfirm = () => {
        setShowDialog(false);
        setUnsavedChanges(false);
        if (nextTab) {
            setTab(nextTab);
        }
        setNextTab(null);
    };

    return (
        <div className="order-2 flex flex-1 flex-col gap-12 lg:order-1 ">
            <Text.H2 className="font-bold">{t('profile.hello', { name: getUserName(user) })}</Text.H2>
            <Tabs defaultValue="orders" value={tab} onValueChange={handleTabChange}>
                <div className="flex items-center justify-center lg:justify-between">
                    <TabsList className="mb-0">
                        <TabsTrigger value="orders">{t('profile.orders')}</TabsTrigger>
                        <TabsTrigger value="billing_details">{t('profile.billing_details')}</TabsTrigger>
                        <TabsTrigger value="delivery_details">{t('profile.delivery_details')}</TabsTrigger>
                        <TabsTrigger value="settings">{t('profile.settings')}</TabsTrigger>
                    </TabsList>
                    <Button
                        className="hidden lg:block"
                        variant="outline"
                        size="md"
                        rounded
                        type="button"
                        onClick={() => {
                            resetCartDetails();
                            signOut({
                                callbackUrl: '/',
                            });
                        }}
                    >
                        {t('profile.logout')}
                    </Button>
                </div>
                <TabsContent value="orders">
                    <div className="mt-6 flex w-full flex-col gap-6">
                        {filteredInvoices.length > 0 ? (
                            filteredInvoices.map((invoice) => (
                                <OrderLink key={invoice.id} invoice={invoice} deliveryMethods={deliveryMethods} />
                            ))
                        ) : (
                            <NoOrdersCard />
                        )}
                        <Pagination
                            url={invoices.url}
                            currentPage={invoices.page}
                            nrOfItems={invoices.totalItems}
                            itemsPerPage={invoices.itemsPerPage}
                        />
                    </div>
                </TabsContent>
                <TabsContent value="billing_details">
                    <BillingDetailsForm
                        countries={countries}
                        user={user}
                        token={token}
                        setUnsavedChanges={setUnsavedChanges}
                    />
                </TabsContent>
                <TabsContent value="delivery_details">
                    <DeliveryDetailsForm
                        user={user}
                        token={token}
                        countries={countries.filter((country) => country.deliveryAllowed)}
                        setUnsavedChanges={setUnsavedChanges}
                    />
                </TabsContent>
                <TabsContent value="settings">
                    <ProfileSettings token={token} user={user} />
                </TabsContent>
            </Tabs>
            <Button
                className="block lg:hidden"
                variant="outline"
                color="dark"
                size="sm"
                rounded
                type="button"
                onClick={() => {
                    resetCartDetails();
                    signOut({
                        callbackUrl: '/',
                    });
                }}
            >
                {t('profile.logout')}
            </Button>
            <Dialog open={showDialog} onOpenChange={setShowDialog}>
                <DialogContent>
                    <Text as="p">{t('unsavedChanges')}</Text>
                    <div className="mt-4 flex flex-row justify-end gap-4">
                        <Button
                            variant="outline"
                            color="dark"
                            size="md"
                            rounded
                            type="button"
                            onClick={() => setShowDialog(false)}
                        >
                            {t('no')}
                        </Button>
                        <Button variant="default" color="dark" size="md" rounded type="button" onClick={handleConfirm}>
                            {t('yes')}
                        </Button>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default Profile;
