import React, { type ReactNode } from 'react';
import { Price, Text } from '@/components/ui';

import { useTranslation } from '@/lib/i18n';
import { ChevronRight } from '@/svg';
import { getHydraCurrency, getHydraId } from '@/lib/hydra';
import dayjs from 'dayjs';
import type { DeliveryMethod } from '@/lib/resources/delivery.ts';
import { deliveryTypes } from '@/constants/delivery.ts';
import type { Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read as Invoice } from '@expanzi/api-types/src/models/Invoice_jsonld_invoice_read_product_read_invoice_detail_file_read';

interface ColumnProps {
    label: string;
    children: ReactNode;
}

const Column: React.FC<ColumnProps> = (props) => {
    const { children, label } = props;
    return (
        <div className="flex flex-col gap-3 rounded-md bg-white">
            <Text.Body className="whitespace-nowrap font-bold">{label}</Text.Body>
            <Text.Body className="whitespace-nowrap">{children}</Text.Body>
        </div>
    );
};

interface Props {
    invoice: Invoice;
    deliveryMethods: DeliveryMethod[];
}

const OrderItem: React.FC<Props> = (props) => {
    const { invoice, deliveryMethods } = props;

    const { t } = useTranslation();

    const deliveryMethod = deliveryMethods.find((method) => method.id === invoice.deliveryMetadata?.provider);

    return (
        <div className="rounded-md bg-white p-8">
            <div className="flex flex-col justify-between gap-6 border-b pb-8 md:flex-row">
                <Text.H5 className="font-bold">{t('order.order_vs', { vs: invoice.variableSymbol ?? '' })}</Text.H5>
                <a href={`/orders/${getHydraId(invoice?.id)}`} className="flex items-center gap-2">
                    <Text.H5 className="text-primary-500 whitespace-nowrap font-bold">{t('order.to_detail')}</Text.H5>
                    <ChevronRight />
                </a>
            </div>
            <div className="mt-8 grid grid-cols-1 gap-6 md:grid-cols-3 2xl:grid-cols-5">
                <Column label={t('order.date_order_time')}>
                    {dayjs(invoice.createdAt).format('DD.MM.YYYY, HH:mm')}
                </Column>
                <Column label={`${t('order.amount')} (${t('with_tax')})`}>
                    <Price currency={getHydraCurrency(invoice.currency)} value={Number(invoice.totalPriceCharged)} />
                </Column>
                <Column label={t('order.delivery_method')}>
                    {deliveryMethod && deliveryMethod.type === deliveryTypes.zasilkovna
                        ? t('order.zasilkovna')
                        : t('order.personal_pick_up')}
                </Column>
                <Column label={t('order.order_state')}>{t(`order.${invoice.state}`)}</Column>
            </div>
        </div>
    );
};

export default OrderItem;
